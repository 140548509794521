import {
  IMAGE_SIZE_THUMB_WIDTH,
  IMAGE_SIZE_MEDIUM_WIDTH,
  IMAGE_SIZE_REGULAR_WIDTH,
  IMAGE_SIZE_LARGE_WIDTH,
  IMAGE_SIZE_FULL_WIDTH,
} from '@/storychief/constants/Constants';
import StoryChief from '../../storychief';
import convertSignedUrlToCloudfrontUrl from '../../storychief/utils/convertSignedUrlToCloudfrontUrl';

function getImageFormat(width, retina = false) {
  return `_${width * (retina ? 2 : 1)}`;
}

function getImageWidth(size, retina = false) {
  switch (size) {
    case 'thumb':
      return getImageFormat(IMAGE_SIZE_THUMB_WIDTH, retina);
    case 'medium':
      return getImageFormat(IMAGE_SIZE_MEDIUM_WIDTH, retina);
    case 'large':
      return getImageFormat(IMAGE_SIZE_LARGE_WIDTH, retina);
    case 'full':
      return getImageFormat(IMAGE_SIZE_FULL_WIDTH, false);
    case 'regular':
    default:
      return getImageFormat(IMAGE_SIZE_REGULAR_WIDTH, retina);
  }
}

export default function getImageSizeSrc(src, size, retina = false) {
  let source = src;

  // If no source provided return null,
  // because images in view components that use this util function and have empty src,
  // usually fall back to a specific placeholder
  if (!source) {
    return null;
  }

  // blob files are not resized
  if (source.startsWith('blob:') || !source.startsWith(StoryChief.cloudfront.url)) {
    return source;
  }

  // .gif files are not resized
  if (source.toLowerCase().endsWith('.gif')) {
    return source;
  }

  // support for older stories where src was not converted to cloudfront url
  if (source.includes('amazonaws.com')) {
    source = convertSignedUrlToCloudfrontUrl(source, StoryChief.cloudfront.url);
  }

  // change bucket and filename
  const srcSplit = source.replace(StoryChief.cloudfront.url, '').split('/');
  const imageSplit = srcSplit[srcSplit.length - 1].split('.');
  imageSplit[0] += getImageWidth(size, retina);
  srcSplit[2] = imageSplit.join('.');
  return `${StoryChief.cloudfront.outputUrl}${srcSplit.join('/')}`;
}
