export default function renameFile(originalFile, newName) {
  const renamedFile = new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });

  Object.entries(originalFile).forEach(([key, value]) => {
    renamedFile[key] = value;
  });

  return renamedFile;
}
