import loadImage from '@/storychief/utils/loadImage';

export default function getImageDimensions(_image) {
  return new Promise((resolve) => {
    if (_image?.width > 0 && _image?.height > 0) {
      resolve({
        width: _image.width,
        height: _image.height,
      });
    } else {
      const src = _image.id ? _image.url : URL.createObjectURL(_image);
      loadImage(src).then((_img) => {
        resolve({
          width: _img.naturalWidth,
          height: _img.naturalHeight,
        });
      });
    }
  });
}
