/* eslint-disable no-param-reassign */
import renameFile from '@/storychief/utils/renameFile';
import getImageDimensions from '@/storychief/utils/getImageDimensions';
import getImageData from '@/storychief/utils/getImageData';
import getFileMimeTypeDiscreteType from '@/files/utils/getFileMimeTypeDiscreteType';

export default function getFilesDetails(_files) {
  return new Promise((resolveDetails) => {
    Promise.all(
      _files.map((_originalFile) => {
        let _file = _originalFile;

        /*
        We shorten file names of length longer than 100 chars,
        to prevent overflowing 191 chars constraint in url field of Image, and Video models.
        */
        if (_originalFile?.name?.length > 100) {
          const shortenedFileName = _originalFile.name.slice(-100);
          _file = renameFile(_originalFile, shortenedFileName);
        }

        return new Promise((resolve) => {
          switch (getFileMimeTypeDiscreteType(_file)) {
            case 'image':
              Promise.all([getImageDimensions(_file), getImageData(_file)]).then(
                ([dimensions, { description, creator, copyright }]) => {
                  _file.__typename = 'Image';
                  _file.width = dimensions.width;
                  _file.height = dimensions.height;
                  _file.description = description;
                  _file.user = creator;
                  _file.copyright = copyright;
                  resolve(_file);
                },
              );
              break;
            case 'video': {
              if (
                hasOwnProperty.call(_file, 'width') &&
                hasOwnProperty.call(_file, 'height') &&
                hasOwnProperty.call(_file, 'length') &&
                hasOwnProperty.call(_file, 'thumbnail')
              ) {
                resolve(_file);
              } else {
                const videoUrl = _file.id ? _file.url : URL.createObjectURL(_file);
                const videoTitle = _file.name ? _file.name.replace(/\.[^/.]+$/, '') : '';
                const video = document.createElement('video');

                video.addEventListener('seeked', () => {
                  const videoLength = video.duration;
                  const videoWidth = video.videoWidth;
                  const videoHeight = video.videoHeight;
                  let canvas = document.createElement('canvas');
                  canvas.height = videoHeight;
                  canvas.width = videoWidth;
                  canvas.getContext('2d').drawImage(video, 0, 0);
                  const thumbnail = canvas.toDataURL('image/jpg');
                  _file.__typename = 'Video';
                  _file.length = Math.ceil(videoLength);
                  _file.width = videoWidth;
                  _file.height = videoHeight;
                  fetch(thumbnail)
                    .then((res) => res.blob())
                    .then((blob) => {
                      _file.thumbnail = new File([blob], `${videoTitle}-thumb.jpg`, {
                        type: 'image/jpg',
                      });
                      video.src = '';
                      video.load();
                      canvas = null;
                      resolve(_file);
                    });
                });
                video.addEventListener('loadeddata', () => {
                  video.currentTime = 0;
                });
                video.src = videoUrl;
                video.load();
              }
              break;
            }
            default:
              resolve(_file);
              break;
          }
        });
      }),
    ).then((filesWithDetails) => {
      resolveDetails(filesWithDetails);
    });
  });
}
