import SparkMD5 from 'spark-md5';
import StoryChief from '@/storychief';

/**
 * @param {object} file
 * @param {{ onProgress: Function, onError: Function, onFinish: Function }} callbacks
 */
export default function s3UploadPost(
  file,
  { onProgress = () => null, onError = () => null, onFinish = () => null },
) {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = () => {
    // https://michalsn.dev/posts/upload-files-directly-to-s3-with-dropzonejs-and-codeigniter-4/
    const hash = btoa(SparkMD5.hashBinary(reader.result, true));

    fetch(`${StoryChief.apiBasePath}/s3/signed-url`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': StoryChief.csrfToken,
      },
      body: JSON.stringify({
        objectName: file.name,
        contentType: file.type,
        contentMD5: hash,
      }),
    })
      .then((response) => response.json())
      .then(({ formInputs, formAttributes, publicUrl }) => {
        const formData = new FormData();

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const key in formInputs) {
          formData.append(key, formInputs[key]);
        }

        formData.append('file', file);

        const xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.upload.onprogress = (e) => {
          const position = e.loaded || e.position;
          const total = e.total;
          const progress = Math.ceil((position / total) * 100);

          onProgress(file, progress);
        };
        xhr.onerror = (e) => onError(file, e);
        xhr.onload = () => onFinish(file, publicUrl);

        xhr.open(formAttributes.method, formAttributes.action, true);
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.send(formData);
      });
  };
}
