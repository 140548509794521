import ExifReader from 'exifreader/dist/exif-reader';

function getExifDescription(tags) {
  const caption = tags['Caption/Abstract'];

  if (caption?.description) {
    return caption.description;
  }

  return null;
}

function getExifCreator(tags) {
  const creatorObject = tags.ImageCreator?.value?.[0];

  return {
    name: creatorObject?.ImageCreatorName.value || tags.creator?.description || null,
    url: creatorObject?.ImageCreatorID.value || null,
  };
}

function getExifCopyright(tags) {
  const copyrightObject = tags.CopyrightOwner?.value?.[0];

  return {
    name: copyrightObject?.CopyrightOwnerName.value || tags.Copyright?.description || null,
    url: copyrightObject?.CopyrightOwnerID.value || null,
  };
}

function getExifData(image) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (readerEvent) => {
      try {
        const tags = ExifReader.load(readerEvent.target.result);
        resolve({
          description: image?.description || getExifDescription(tags),
          creator: image?.user?.name ? image.user : getExifCreator(tags),
          copyright: getExifCopyright(tags),
        });
      } catch (error) {
        resolve({ description: null, creator: null });
      }
    };

    try {
      reader.readAsArrayBuffer(image);
    } catch {
      resolve({ description: null });
    }
  });
}

export default function getImageData(image) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (!image.id) {
      resolve(await getExifData(image));
    } else if (image.description) {
      resolve({ description: image.description });
    } else {
      const imageBlob = await fetch(image.url).then((r) => r.blob());
      resolve(await getExifData(imageBlob));
    }
  });
}
