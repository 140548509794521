import { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import convertSignedUrlToCloudfrontUrl from '@/storychief/utils/convertSignedUrlToCloudfrontUrl';
import StoryChief from '@/storychief';
import getFileMimeTypeDiscreteType from '@/files/utils/getFileMimeTypeDiscreteType';
import getFilesDetails from '@/files/utils/getFilesDetails';
import loadImage from '@/storychief/utils/loadImage';
import getImageSizeSrc from '@/editor/utils/getImageSizeSrc';
import s3UploadPost from '@/s3-upload-post';
import { ErrorsMiddleware } from '@/graphql/middlewares';

const CREATE_IMAGE_MUTATION = gql`
  mutation CreateImage($input: CreateImageInput!) {
    createImage(input: $input) {
      __typename
      id
      height
      url
      updated_at
      mime_type
      derived
      source_id
      width
      file_size
      deleted_at
      created_at
      account_id
      can {
        delete
      }
    }
  }
`;

const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      __typename
      id
      url
      file_name
      updated_at
      mime_type
      document_thumbnail
      info
      number_of_pages
      file_size
      deleted_at
      created_at
      account_id
    }
  }
`;

const DELETE_IMAGE_FILE_FROM_STORAGE_MUTATION = gql`
  mutation DeleteImageFileFromStorage($input: DeleteImageFileFromStorageInput!) {
    deleteImageFileFromStorage(input: $input) {
      url
    }
  }
`;

const CREATE_VIDEO_MUTATION = gql`
  mutation CreateVideo($input: CreateVideoInput!) {
    createVideo(input: $input) {
      __typename
      id
      height
      length
      url
      updated_at
      mime_type
      width
      thumbnail
      file_size
      deleted_at
      created_at
      account_id
      can {
        delete
      }
    }
  }
`;

export default function useUploadFiles({
  saveToDatabase = false,
  isPrivate = false,
  onUploading = null,
  onCompleted = null,
  onError = () => {},
}) {
  const [files, setFiles] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [called, setCalled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [createImage] = useMutation(CREATE_IMAGE_MUTATION, {
    onError: handleOnError,
  });
  const [deleteImageFileFromStorage] = useMutation(DELETE_IMAGE_FILE_FROM_STORAGE_MUTATION, {
    onError: handleOnError,
  });
  const [createVideo] = useMutation(CREATE_VIDEO_MUTATION, {
    onError: handleOnError,
  });
  const [createDocument] = useMutation(CREATE_DOCUMENT_MUTATION, {
    context: { middlewares: [new ErrorsMiddleware()] },
    onError: handleOnError,
  });
  useEffect(() => {
    if (uploading && files && files.length) {
      if (files.filter((_file) => !_file.uploading).length === files.length) {
        setUploading(false);
        if (onCompleted) onCompleted(files);
      } else {
        const _uploadProgress = Math.floor(
          files.reduce((acc, _file) => acc + (_file.uploading ? _file.uploadProgress : 100), 0) /
            files.length,
        );
        setUploadProgress(_uploadProgress);
        if (onUploading) onUploading(files);
      }
    }
  }, [files, uploading]);

  function handleOnError(error) {
    onError(error);
    throw new Error(error);
  }

  function saveFileToDatabase(urlConverted, file) {
    switch (getFileMimeTypeDiscreteType(file)) {
      case 'image':
        createImage({
          variables: {
            input: {
              url: urlConverted,
              width: file.width,
              height: file.height,
              mime_type: file.type,
              file_size: file.size,
              source_id: file.data?.sourceId,
              derived: file.data?.derived,
              is_private: isPrivate,
            },
          },
        }).then(({ data } = {}) => {
          setFiles((prevFiles) =>
            prevFiles.map((_prevFile) => {
              if (file.name === _prevFile.name) {
                return {
                  ..._prevFile,
                  ...data.createImage,
                  uploading: false,
                };
              }
              return _prevFile;
            }),
          );
        });
        break;
      case 'document':
        createDocument({
          variables: {
            input: {
              url: urlConverted,
              file_name: file.name,
              mime_type: file.type,
              file_size: file.size,
              is_private: isPrivate,
            },
          },
        })
          .then(({ data } = {}) => {
            setFiles((prevFiles) =>
              prevFiles.map((_prevFile) => {
                if (file.name === _prevFile.name) {
                  return {
                    ..._prevFile,
                    ...data.createDocument,
                    uploading: false,
                  };
                }
                return _prevFile;
              }),
            );
          })
          .catch(() => {});
        break;
      case 'video': {
        // Generate thumbnail of the image
        s3UploadPost(file.thumbnail, {
          onError: onS3UploaderError,
          onFinish: (_, publicUrl) => {
            const urlConvertedThumbnail = convertSignedUrlToCloudfrontUrl(
              publicUrl,
              StoryChief.cloudfront.url,
            );
            createVideo({
              variables: {
                input: {
                  url: urlConverted,
                  thumbnail: urlConvertedThumbnail,
                  width: file.width,
                  height: file.height,
                  mime_type: file.type,
                  file_size: file.size,
                  length: file.length,
                  is_private: isPrivate,
                },
              },
            }).then(({ data } = {}) => {
              setFiles((prevFiles) =>
                prevFiles.map((_prevFile) => {
                  if (file.name === _prevFile.name) {
                    return {
                      ..._prevFile,
                      ...data.createVideo,
                      uploading: false,
                    };
                  }
                  return _prevFile;
                }),
              );
            });
          },
        });
        break;
      }
      default:
        setFiles((prevFiles) =>
          prevFiles.map((_prevFile) => {
            if (_prevFile.name === file.name) {
              return {
                ..._prevFile,
                url: urlConverted,
                uploading: false,
              };
            }
            return _prevFile;
          }),
        );
    }
  }

  function onS3UploaderProgress(file, progress) {
    setFiles((prevFiles) =>
      prevFiles.map((_prevFile) =>
        _prevFile.name === file.name
          ? {
              ..._prevFile,
              uploadProgress: progress,
            }
          : _prevFile,
      ),
    );
  }

  function onS3UploaderError(file) {
    setFiles((prevFiles) =>
      prevFiles.map((_prevFile) =>
        _prevFile.name === file.name
          ? {
              ..._prevFile,
              error: true,
            }
          : _prevFile,
      ),
    );
  }

  function onS3UploaderFinish(file, publicUrl) {
    const urlConverted = convertSignedUrlToCloudfrontUrl(publicUrl, StoryChief.cloudfront.url);

    const fileResizeValid = new Promise((resolve, reject) => {
      if (getFileMimeTypeDiscreteType(file) === 'image') {
        const resizedUrl = getImageSizeSrc(urlConverted, 'regular', false);
        loadImage(resizedUrl).then(resolve).catch(reject);
      } else {
        resolve();
      }
    });
    fileResizeValid
      .then(() => {
        if (saveToDatabase) {
          saveFileToDatabase(urlConverted, file);
        } else {
          setFiles((prevFiles) =>
            prevFiles.map((_prevFile) => {
              if (_prevFile.name === file.name) {
                return {
                  ..._prevFile,
                  url: urlConverted,
                  uploading: false,
                };
              }
              return _prevFile;
            }),
          );
        }
      })
      .catch(async (error) => {
        setFiles((prevFiles) =>
          prevFiles.map((_prevFile) => {
            if (_prevFile.name === file.name) {
              return {
                ..._prevFile,
                uploading: true,
                error: true,
              };
            }
            return _prevFile;
          }),
        );
        await deleteImageFileFromStorage({
          variables: {
            input: {
              url: urlConverted,
            },
          },
        });
        throw error;
      });
  }

  function initUpload(_files) {
    setUploading(true);

    _files.forEach((file) => {
      s3UploadPost(file, {
        onProgress: onS3UploaderProgress,
        onError: onS3UploaderError,
        onFinish: onS3UploaderFinish,
      });
    });
  }

  function upload(_files) {
    setCalled(true);
    // get image details
    getFilesDetails(_files).then((_filesWithDetails) => {
      setFiles(
        _filesWithDetails.map((_fileWithDetails) => ({
          file_size: _fileWithDetails.size,
          mime_type: _fileWithDetails.type,
          name: _fileWithDetails.name,
          data: _fileWithDetails.data,
          uploadProgress: 0,
          uploading: true,
          url: _fileWithDetails.url || URL.createObjectURL(_fileWithDetails),
          ...(_fileWithDetails.width && { width: _fileWithDetails.width }),
          ...(_fileWithDetails.height && { height: _fileWithDetails.height }),
          ...(_fileWithDetails.description && {
            description: _fileWithDetails.description,
          }),
          ...(_fileWithDetails.alt && {
            alt: _fileWithDetails.alt,
          }),
          ...(_fileWithDetails.caption && {
            caption: _fileWithDetails.caption,
          }),
          ...(_fileWithDetails.user && {
            user: _fileWithDetails.user,
          }),
          ...(_fileWithDetails.copyright && {
            copyright: _fileWithDetails.copyright,
          }),
          ...(_fileWithDetails.length && { length: _fileWithDetails.length }),
          ...(_fileWithDetails.thumbnail && { thumbnail: _fileWithDetails.thumbnail }),
          ...(_fileWithDetails.source && { source: _fileWithDetails.source }),
          ...(_fileWithDetails.external_id && {
            external_id: _fileWithDetails.external_id,
          }),
        })),
      );
      initUpload(_filesWithDetails);
    });
  }

  return [
    upload,
    {
      files,
      uploadProgress,
      uploading,
      called,
    },
  ];
}
