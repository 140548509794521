export default function getFileMimeTypeDiscreteType(file) {
  let discreteType = null;
  const mimeType = file.mime_type || file.type;
  if (typeof mimeType === 'string') {
    if (mimeType === 'application/pdf') {
      discreteType = 'document';
    } else {
      const mimeTypeDetails = mimeType.split('/');
      discreteType = mimeTypeDetails[0];
    }
  }
  return discreteType;
}
